import React, {useState} from 'react';
import {Nav} from "../../components";
import Check from '../../images/icons/success.svg';
import LeaveUsInfo from '../../images/sell-in-bullk/leave-us-info.svg';
import ShipYourDevices from '../../images/sell-in-bullk/ship-your-device.svg';
import GetPaidFast from '../../images/sell-in-bullk/get-paid.svg';
import '../../style/sell-in-bulk.scss'
import {Button} from "../../components/common";
import Footer from "../../components/footer";
import {addDoc, collection} from "firebase/firestore/lite";
import {db} from "../../firebase";
import {CONTACT_COLLECTION_URL, REACH_OUTS_TYPES} from "../../data/consts";

const SellInBulk = ({location}) => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [phone, setPhone] = useState("");

    const sendEmail = async (e) => {
        e.preventDefault();

        const col  = collection(db, CONTACT_COLLECTION_URL)
        const result = await addDoc(col, {
            name,
            email,
            message,
            phone,
            date: (new Date()).getUTCDate(),
            type: REACH_OUTS_TYPES.BULK_CONTACT
        })

        if(result.id) {
            window.location.href = '/thank-you';

            setName('');
            setEmail('');
            setMessage('');
        } else {
            alert('Unfortunately we couldn\'t get your email')
        }
    }

    return <div className='siteWrapper'>
        <Nav
            location={location}
            title={'Turn your company’s old devices into cash'}
            darkNav={true}
        />

        <div className="pageContainer sell-in-bulk">
            <header>
                <h1>Turn your company’s old devices into cash</h1>
            </header>

            <section className={'intro-section'}>
                <div className="text">
                    <p>
                        Do you have many unused and unwanted phones at home or want to replace your business mobile
                        phones with new ones? We help you to sell or dispose of phones in large numbers.
                    </p>

                    <p>
                        Swaptecs will deliver the best offer for anyone with 10 or more devices to sell. Selling phones
                        in bulk is better than selling one at a time, as bulk orders save resources and are processed
                        faster.
                    </p>
                </div>

                <div className="bonuses">
                    <ul>
                        <li>
                            <img src={Check} alt=""/>
                            <span>Save on shipping resources</span>
                        </li>
                        <li>
                            <img src={Check} alt=""/>
                            <span>Receive better bulk rates</span>
                        </li>
                        <li>
                            <img src={Check} alt=""/>
                            <span>We accept broken phones</span>
                        </li>
                        <li>
                            <img src={Check} alt=""/>
                            <span>Data wiping upon arrival</span>
                        </li>
                        <li>
                            <img src={Check} alt=""/>
                            <span>Better for the environment</span>
                        </li>
                    </ul>
                </div>
            </section>

            <section className={'steps'}>
                <ul>
                    <li>
                        <img src={LeaveUsInfo} alt=""/>
                        <h4>Leave us info</h4>
                        <p>
                            List the models and condition, get the best quote for your devices
                        </p>
                    </li>
                    <li>
                        <img src={ShipYourDevices} alt=""/>
                        <h4>Ship your devices</h4>
                        <p>
                            Just pack up your device and ship We will coordinate shipping
                        </p>
                    </li>
                    <li>
                        <img src={GetPaidFast} alt=""/>
                        <h4>Get paid fast</h4>
                        <p>
                            You will receive payment as soon as your devices are inspected
                        </p>
                    </li>
                </ul>
            </section>

            <section className="bulk-form">
                <div className="bulk-form-info">
                    <h2>Sell in bulk</h2>
                    <p>
                        Save on the postage and shipping costs as well as the time taken for shipping and processing the
                        order.
                    </p>

                    <p className={'additional-info color-waterloo'}>
                        If you wish to write us an email instead please use:
                    </p>

                    <a href="mailto:business@swaptecs.com">
                        <b>business@swaptecs.com</b>
                    </a>
                </div>

                <div className="bulk-form-inputs">
                    <form action="post" onSubmit={e => sendEmail(e)}>
                        <div className="input-group">
                            <label htmlFor="name">Your name <span className={'color-red'}>*</span></label>
                            <input
                                type="text"
                                name={'name'}
                                id={'name'}
                                placeholder={'Example: John Smith'}
                                onChange={e => setName(e.target.value)}
                            />
                        </div>

                        <div className="input-group">
                            <label htmlFor="email">Your email address <span className={'color-red'}>*</span></label>
                            <input
                                type="email"
                                name={'email'}
                                id={'email'}
                                placeholder={'Example: john@mail.com'}
                                onChange={e => setEmail(e.target.value)}
                            />
                        </div>

                        <div className="input-group">
                            <label htmlFor="phone">Mobile phone</label>
                            <input
                                type="text"
                                name={'phone'}
                                id={'phone'}
                                placeholder={'Example: +1 (123) 456-7890'}
                                onChange={e => setPhone(e.target.value)}
                            />
                        </div>

                        <div className="input-group">
                            <label htmlFor="additional-info">Message <span className={'color-red'}>*</span></label>
                            <textarea
                                name="additional-info"
                                id="additional-info"
                                placeholder={'Your message...'}
                                onChange={e =>  setMessage(e.target.value)}
                            />
                        </div>

                        <Button
                            className={'primary filled long'}
                            label={'Submit'}
                        />
                    </form>
                </div>
            </section>
        </div>

        <Footer />
    </div>
};

export default SellInBulk;